import { useEffect, useState } from "react";
import { CardPinInfosQuery } from "../graphql/live-unauthenticated";
import { env } from "../utils/env";
import { useScript } from "./useScript";

export const useSmartPinJs = (
  input: CardPinInfosQuery["cardPINInfos"],
): [{ fetching: boolean; error: string | undefined; pin: string | undefined }] => {
  const status = useScript(env.IDEMIA_SMART_PIN_SCRIPT_URL);

  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [pin, setPin] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (input) {
      const { urlToCall, mac, consumerId, requestRef, costumerRef, controlValue, time } = input;

      if (status === "ready" && typeof SmartPinJS !== "undefined") {
        SmartPinJS.getPin(
          urlToCall,
          requestRef,
          consumerId,
          costumerRef,
          controlValue,
          time,
          mac,
          false,
          (pin, result, msg) => {
            if (result) {
              setFetching(false);
              setPin(pin);
              setError(undefined);
            } else {
              setFetching(false);
              setPin(undefined);
              setError(msg);
            }
          },
        );
      } else if (status === "error") {
        setFetching(false);
        setError("Error while loading SmartPinJS");
      }
    }
  }, [input, status]);

  return [{ fetching, error, pin }];
};
